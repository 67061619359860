import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private _httpClient: HttpClient) { }

  changepassword(userDetails) {
    let url = environment.URL + "/api/v1/json/user/changepassword";
    return this._httpClient.post(url, userDetails);
  }
}
