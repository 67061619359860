// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
// State
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout } from '../../../../core/auth';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
	constructor(private store: Store<AppState>, private _route: Router) {
	}

}
