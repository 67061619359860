import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private _httpClient: HttpClient) { }

  getAllSettings() {
    let url = environment.URL + "/v1/json/settings";
    return this._httpClient.get(url);
  }

  addSettings(data) {
    let url = environment.URL + "/api/v1/json/settings";
    return this._httpClient.post(url, data);
  }

}
