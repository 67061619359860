// Angular
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
//delete popup
import { ConfirmComponent, ConfirmDialogModel } from '../../../crud/confirm/confirm.component';
import { Router } from '@angular/router';
// RXJS
import { tap } from 'rxjs/operators';
import { from, merge, Observable } from 'rxjs';
// Crud
import { QueryParamsModel } from '../../../../../../core/_base/crud';
// Layout
import { DataTableItemModel, DataTableService } from '../../../../../../core/_base/layout';
import { DataTableDataSource } from './data-table.data-source';
import { FormGroup, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { User, currentUser } from 'src/app/core/auth';
//file-saver
import saveAs from 'file-saver';


const SEARCH_PARAMS = {
	requestType: "",
	reqCurrentStatus: "",
	fromDate: null,
	toDate: null,
	searchValue: "",
}

@Component({
	selector: 'kt-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
	// Public properties
	dataSource: DataTableDataSource;

	// displayedColumns = ['id', 'cManufacture', 'cModel', 'cMileage', 'cColor', 'cPrice', 'cCondition', 'cStatus', 'actions' ];
	displayedColumns = ['cardType', 'mobileNo', 'name', 'aadharCard', 'divisionName', 'hospitalName', 'requestDttm', 'processedBy', 'verifiedBy', 'approvedBy', 'currentStatus', 'actions'];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	selection = new SelectionModel<DataTableItemModel>(true, []);
	dataTableItemModel: DataTableItemModel;
	searchForm: FormGroup
	filterStatus: "";
	searchVO: any;
	userRole: string | null = "";
	/**
	 * Component 
	 *
	 * @param dataTableService: DataTableService
	 */
	constructor(public dialog: MatDialog,
		private changeDetectorRef: ChangeDetectorRef,
		private store: Store<AppState>, private dataTableService: DataTableService, private _router: Router, private fb: FormBuilder) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				tap(() => {
					this.loadItems();
				})
			)
			.subscribe();

		this.searchVO = {
			requestType: "",
			reqCurrentStatus: "",
			fromDate: null,
			toDate: null,
			searchValue: ""
		}

		// Init DataSource
		this.dataSource = new DataTableDataSource(this.dataTableService);
		this.initSearchForms();
		this.getCurrentUserData();
	}

	getCurrentUserData() {
		let loggedUser: Observable<User> = this.store.pipe(select(currentUser));
		loggedUser.subscribe(res => {
			if (res) {
				this.userRole = res.role != "Admin" ? (res.role != "superAdmin" ? res.role : null) :null;
				
				if (res.role == "Processor") {
					this.searchVO.reqCurrentStatus = "REQUEST";
				} else if (res.role == "Verifier") {
					this.searchVO.reqCurrentStatus = "PROCESSED";
				}
				else if (res.role == "Approver") {
					this.searchVO.reqCurrentStatus = "VERIFIED";
				}
				// First load
				this.loadItems(true);
				// this.changeDetectorRef.detectChanges();
			}
		});
	}

	/**
	 * Load items
	 *
	 * @param firstLoad: boolean
	 */
	loadItems(firstLoad: boolean = false) {
		const queryParams = new QueryParamsModel(
			{},
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			firstLoad ? 10 : this.paginator.pageSize
		);
		this.dataSource.loadItems(queryParams, this.searchVO);
		this.selection.clear();
	}

	/* UI */

	/**
	 * Returns item status
	 *
	 * @param status: number
	 */
	getItemStatusString(status: number = 0): string {
		console.log(status);

		switch (status) {
			case 0:
				return 'Selling';
			case 1:
				return 'Sold';
		}
		return '';
	}

	initSearchForms() {
		this.searchForm = this.fb.group({
			requestType: [SEARCH_PARAMS.requestType],
			reqCurrentStatus: [SEARCH_PARAMS.reqCurrentStatus],
			fromDate: [SEARCH_PARAMS.fromDate],
			toDate: [SEARCH_PARAMS.toDate],
			searchValue: [SEARCH_PARAMS.searchValue]
		});
	}

	/**
	 * Returens item CSS Class Name by status
	 *
	 * @param status: number
	 */
	getItemCssClassByStatus(status): string {
		switch (status) {
			case "REQUEST":
				return 'new';
			case "PROCESSED":
				return 'processed';
			case "VERIFIED":
				return 'verified';
			case "APPROVED":
				return 'approved';
		}
		return '';
	}

	/**
	 * Returns item condition
	 *
	 * @param condition: number
	 */
	getItemConditionString(condition: number = 0): string {
		switch (condition) {
			case 0:
				return 'New';
			case 1:
				return 'Used';
		}
		return '';
	}

	/**
	 * Returns CSS Class name by condition
	 *
	 * @param condition: number
	 */
	getItemCssClassByCondition(condition: number = 0): string {
		switch (condition) {
			case 0:
				return 'success';
			case 1:
				return 'info';
		}
		return '';
	}


	redirect(appStatusId) {
		this._router.navigate(['dashboard/applicant-detail', appStatusId]);
	}

	onSubmit() {
		this.searchVO = {
			requestType: this.searchForm.value['requestType'],
			reqCurrentStatus: this.searchForm.value['reqCurrentStatus'],
			searchValue: this.searchForm.value['searchValue']
		}

		if (this.searchForm.value['fromDate'])
			this.searchVO.fromDate = formatDate(this.searchForm.value['fromDate'], 'dd-MM-yyyy', 'en');

		if (this.searchForm.value['fromDate'])
			this.searchVO.toDate = formatDate(this.searchForm.value['toDate'], 'dd-MM-yyyy', 'en');

		this.getCurrentUserData();
	}

	onReset() {
		this.initSearchForms();
		this.onSubmit();
	}

	printCardPdf(data) {
		this.dataTableService.printCardPdf(data.appStatusId).subscribe((res: any) => {
			let blob = new Blob([res.body], { type: "application/pdf" })
			window.open(URL.createObjectURL(blob))
		}, (err) => {
			console.log(err)
		})
	}

	openPdf(data) {
		this.dataTableService.openPdf(data.appStatusId).subscribe((res: any) => {
			//console.log(res.body)
			let blob = new Blob([res.body], { type: "application/pdf" })
			let fileName = res.headers.get('content-disposition').split(';')[1].trim().split('=')[1]
			saveAs(blob, data.mobileNo)
		}, (err) => {
			console.log(err)
		})
	}

	openApplicantDetailReport(data) {
		this.dataTableService.openApplicantDetailReport(data.appStatusId).subscribe((res: any) => {
			let blob = new Blob([res.body], { type: "application/pdf" })
			let fileName = data.mobileNo + "_" + res.headers.get('content-disposition').split(';')[1].trim().split('=')[1]
			saveAs(blob, fileName)
		}, (err) => {
			console.log(err)
		})
	}

	deleteApplicantDetail(appStatusId): void {
		const message = `Are you sure you want to delete?`;
		const dialogData = new ConfirmDialogModel("Confirmation", message);
		const dialogRef = this.dialog.open(ConfirmComponent, {
		  maxWidth: "300px",	
		  data: dialogData
		});

		dialogRef.afterClosed().subscribe(dialogResult => { 
		 	if(dialogResult){
				this.dataTableService.deleteApplicantDetail(appStatusId).subscribe(data => {
				this.loadItems(true);
			   },(error) => {console.log(error)})
			}
		});
	  }
}
