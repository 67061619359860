export class DataTableItemModel {
	appStatusId: number;
	mobileNo: string;
	name: string;
	dateOfBirth;
	cardType: string;
	gender: string;
	aadharCard: string;
	hospitalId: number;
	hospitalName: string;
	doctorName: string;
	doctorRegdNo: string;
	handicappedNature: string;
	currentStatus: string;
	requestDttm: string;
	generatedCardNo: string;
	issueDttm: string;
	dueDttm: string;
	escort: string;
	validityConcessionCertificate;
	railwayConcessionCertificate: string;
	disabilityCertificate: string;
	birthProof: string;
	idCard: string;
	addressProof: string;
	photo: string;
	isRailCerPdf: boolean;
	isdisCerPdf: boolean;
	isbirCerPdf: boolean;
	isIdCardPdf: boolean;
	isAddPdf: boolean;
	isPhotoPdf: boolean;
	railwayConcessionCertificateFile: any;
	disabilityCertificateFile;
	birthProofFile;
	idCardFile;
	addressProofFile;
	photoFile;
	divisionId: any;
	divisionName: any;
}