// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// Models
import { DataTableItemModel } from '../models/datatable-item.model';
import { environment } from 'src/environments/environment';

const API_DATATABLE_URL = 'api/cars';

@Injectable()
export class DataTableService {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
	constructor(private http: HttpClient) { }
	dataTableItemModel: DataTableItemModel;

	/**
	 * Returns data from fake server
	 */
	getAllItems(pageNo: number, pageSize: number, searchForm: any, isReport: boolean) {
		let url = environment.URL + "/api/v1/json/applicantdetails?pageNo=" + pageNo + "&pageSize=" + pageSize + "&isReport=" + isReport;
		//return this.http.get(url);
		return this.http.post(url, searchForm);
	}

	getAllApplicantDetails(pageNo: number, pageSize: number) {
		let url = environment.URL + "/api/v1/json/applicantdetaillist?pageNo=" + pageNo + "&pageSize=" + pageSize;
		return this.http.get(url);
	}

	getApplicantRequestCount() {
		let url = environment.URL + "/api/v1/json/applicantrequest/count";
		return this.http.get(url);
	}

	exportPdf(searchVO: any) {
		let uri = environment.URL + "/api/v1/json/exportAsPDF";
		return this.http.post(uri, searchVO, { observe: 'response', responseType: 'blob' });
	}
	exportExcel(searchVO: any) {
		let uri = environment.URL + "/api/v1/json/exportAsExcel";
		return this.http.post(uri, searchVO, { observe: 'response', responseType: 'blob' });
	}

	printCardPdf(appStatusId) {
		let uri = environment.URL + "/api/v1/json/printCardPdf?appStatusId=" + appStatusId;
		return this.http.get(uri, { observe: 'response', responseType: 'blob' });
	}

	openPdf(appStatusId) {
		let uri = environment.URL + "/api/v1/json/print-card?appStatusId=" + appStatusId;
		return this.http.get(uri, { observe: 'response', responseType: 'blob' });
	}

	openApplicantDetailReport(appStatusId) {
		let uri = environment.URL + "/api/v1/json/printApplicantReport?appStatusId=" + appStatusId;
		return this.http.get(uri, { observe: 'response', responseType: 'blob' });
	}
    
	deleteApplicantDetail(appStatusId) {
		let Deleted = "DELETED";
        let uri = environment.URL + "/api/v1/json/applicantdetail/status/update?appStatusId="+ appStatusId +"&status="+ Deleted
		+ "&scheduledDate=" + null + "&timeslotid=" + " " + "&cardIssueDate=" + null + "&cardDueDate=" + null + "&cardNo=" + null;
        return this.http.post(uri,{}, { observe: 'response'}); 
    }
}
