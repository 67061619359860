import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageType, LayoutUtilsService } from 'src/app/core/_base/crud';
import { ConfirmPasswordValidator } from '../auth/register/confirm-password.validator';
import { ChangePasswordService } from './service/change-password.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { Logout } from 'src/app/core/auth';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  user;
  userForm: FormGroup;
  userID = null;
  isLoading: boolean = false;

  constructor(
    private activateRouter: ActivatedRoute,
    public _changePasswordService: ChangePasswordService,
    public layoutUtilsService: LayoutUtilsService,
    private _router: Router,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initUserForm();
    this.userID = this.activateRouter.snapshot.params.id;
    if (this.userID <= 0) {
      this._router.navigate(['/dashboard']);
    }
  }

  initUserForm() {
    this.userForm = this.fb.group({
      newPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(320)
      ]),
      ],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],


    }, {
      validator: ConfirmPasswordValidator.MatchChangePassword
    });
  }


  changePassword() {
    const controls = this.userForm.controls;
    /** check form */
    if (this.userForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }


    this.user = this.userForm.value;
    this.user.id = this.userID;
    this.isLoading = true;
    this._changePasswordService.changepassword(this.user).subscribe((res: any) => {
      this.isLoading = false;
      if (res && res["message"]) {
        this.layoutUtilsService.showActionNotification(res["message"], MessageType.Create, 10000, true, false);
      }
      if (res && res["statusCode"] == 200) {
        this.store.dispatch(new Logout());
      }
      this.changeDetectorRef.detectChanges();

    }, err => {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
      console.log(err);
    })
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.userForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

}
