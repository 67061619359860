// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Partials
import { PartialsModule } from '../partials/partials.module';
import { CKEditorModule } from 'ng2-ckeditor';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SettingsComponent } from './settings/settings.component';
//service
import { ChangePasswordService } from './change-password/service/change-password.service';
import { SettingService } from './settings/service/setting.service';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatProgressSpinnerModule } from '@angular/material';
import { ChangeNumberComponent } from './change-number/change-number.component';
import { ChangeNumberService } from './change-number/service/change-number.service';

@NgModule({
	declarations: [MyPageComponent, ChangePasswordComponent, SettingsComponent, ChangeNumberComponent],
	exports: [],
	imports: [
		CommonModule,
		PerfectScrollbarModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		ReactiveFormsModule,
		RouterModule,
		NgbTabsetModule,
		CKEditorModule,
		MatProgressSpinnerModule,

	],
	providers: [ChangeNumberService, ChangePasswordService, SettingService]
})
export class PagesModule {
}
