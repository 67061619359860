// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { ChangePasswordComponent } from './views/pages/change-password/change-password.component';
import { ChangeNumberComponent } from './views/pages/change-number/change-number.component';
import { SettingsComponent } from './views/pages/settings/settings.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{ path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule) },

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'add-new-form',
				loadChildren: () => import('../app/views/pages/add-new-form/add-new-form.module').then(m => m.AddNewFormModule)
			},
			{
				path: 'users',
				loadChildren: () => import('../app/views/pages/users/users.module').then(m => m.UsersModule)
			},
			{
				path: 'reports',
				loadChildren: () => import('../app/views/pages/reports/reports.module').then(m => m.ReportsModule)
			}
			,
			{
				path: 'divisions',
				loadChildren: () => import('../app/views/pages/division/division.module').then(m => m.DivisionModule)
			}
			,
			{
				path: 'hospitals',
				loadChildren: () => import('../app/views/pages/hospital-master/hospital-master.module').then(m => m.HospitalMasterModule)
			},
			{
				path: 'concession-document',
				loadChildren: () => import('../app/views/pages/concession-document/concession-document.module').then(m => m.ConcessionDocumentModule)
			},
			{
				path: 'card-range',
				loadChildren: () => import('../app/views/pages/card-range/card-range.module').then(m => m.CardRangeModule)
			},
			{
				path: 'time-slot',
				loadChildren: () => import('../app/views/pages/time-slot/time-slot.module').then(m => m.TimeSlotModule)
			}
			,
			{
				path: 'zones',
				loadChildren: () => import('../app/views/pages/zone/zone.module').then(m => m.ZoneModule)
			}
			,
			{
				path: 'mail',
				loadChildren: () => import('../app/views/pages/apps/mail/mail.module').then(m => m.MailModule)
			},
			{
				path: 'ecommerce',
				loadChildren: () => import('../app/views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			},
			{
				path: 'ngbootstrap',
				loadChildren: () => import('../app/views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule)
			},
			{
				path: 'material',
				loadChildren: () => import('../app/views/pages/material/material.module').then(m => m.MaterialModule)
			},
			{
				path: 'user-management',
				loadChildren: () => import('../app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			},
			{
				path: 'wizard',
				loadChildren: () => import('../app/views/pages/wizard/wizard.module').then(m => m.WizardModule)
			},
			{
				path: 'builder',
				loadChildren: () => import('../app/views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'change-password/:id',
				component: ChangePasswordComponent,
			},
			{
				path: 'change-phone-number/:id',
				component: ChangeNumberComponent,
			},
			{
				path: 'settings',
				component: SettingsComponent,
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{ path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
		]
	},

	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
