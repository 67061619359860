import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { SettingService } from './service/setting.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  currentOrientation = 'horizontal';
  settings = {
    termsAndCondition: "",
    aboutUs: " ",
    contactUs: null
  }
  isLoading: boolean = false;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public _layoutUtilsService: LayoutUtilsService,
    private _settingService: SettingService
  ) { }

  ngOnInit() {
    this.getAllSettingDetails();
  }

  getAllSettingDetails() {
    this.isLoading = true;
    this._settingService.getAllSettings().subscribe((res) => {
      this.isLoading = false;
      if (res && res["statusCode"] == 200) {
        let data = res["result"];
        this.settings.termsAndCondition = data["TERMS_AND_CONDITION"];
        this.settings.aboutUs = data["ABOUT_US"];
        this.settings.contactUs = data["CONTACT_US"];
        this.changeDetectorRef.detectChanges();
      }
    })
  }


  addEditContactDetail(settingName) {
    let addEditSetting = {
      detailType: {},
      description: null
    }
    if (settingName == "CONTACT_US") {
      addEditSetting.detailType = "CONTACT_US"
      addEditSetting.description = this.settings.contactUs

    }
    if (settingName == "TERMS_AND_CONDITION") {
      addEditSetting.detailType = "TERMS_AND_CONDITION"
      addEditSetting.description = this.settings.termsAndCondition

    }
    if (settingName == "ABOUT_US") {
      addEditSetting.detailType = "ABOUT_US"
      addEditSetting.description = this.settings.aboutUs
    }
    this.isLoading = true;
    this._settingService.addSettings(addEditSetting).subscribe((res) => {
      this.isLoading = false;
      if (res && res["message"]) {
        this._layoutUtilsService.showActionNotification(res["message"], MessageType.Create, 10000, true, false);
      }
      if (res && res["status"] == "SUCCESS") {
        this.ngOnInit();
      }
    }, err => {
      console.log(err);
      this.isLoading = false;
    })

  }

}
